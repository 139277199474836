.actualites-carousel {
  .carousel-caption {
    padding: 0.5rem;
    margin-bottom: 0;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .news-item {
    height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
