html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.fontStyle {
	display: inline;
	padding-left: 20px;
	font-size: 20px;
	font-weight: bolder;
	padding: 20px 0px 20px 0px;
}

.margin {
	padding: 20px 0px 20px 0px;
	margin-top: 0px;
}

.cardPosition {

	border-radius: 6px;
	position: relative;	
	top: 50px;
	left: 80px;
	padding: 30px;
	box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25);
	/*overflow: scroll;
	height : 400px;
	*/

}

.bottom {
	padding-bottom: 200px;
}

.top {
	margin-top: 100px;
}