$font-family-base: 'Montserrat', sans-serif;
$color-tangerine: #ee9f30;
$color-madison: #07294D;
$color-matisse: #2258A6;
$color-jupiter: #6B8F92;
$color-curious-blue: #3487D6;
$color-tory-blue: #0A3764;
$color-russett: #0A3764;
$color-jungle-mist: #A4CBCD;
$color-footer: #383838;
$color-gallery: #F0F0F0;
$color-gray: #777777;
$primary: $color-madison;
$secondary: $color-tangerine;
$dark: #1CB3FF;
