.home-more-resources {
  position: relative;
  padding-top: 3em;
  padding-bottom: 5rem;

  .ressources-content {
    margin-top: 3rem;
  }

  .col-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .card {
    text-align: center;
    height: 100%;
    background-color: #f9f9f9;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);

    .card-body {
      text-align: left;
    }

    .resource-img {
      border-radius: 50rem;
      border: 1px solid rgba(0,0,0,.125);
      padding: 1rem;
      width: 5rem;
      height: 5rem;
      margin-right: 1rem;;
      background-color: #f9f9f9;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    h4 {
      word-break: break-word;
    }
  }
}

.card2 {
  // display: block;
  top: 0px;
  position: relative;
  // text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: #07294D;

    a{
      color: #fff;
    }
  }

  &:hover:before {
    transform: scale(2.15);
  }
}


@include media-breakpoint-down(md) {
  .home-more-resources .card {
    .resource-img {
      padding: 0;
      width: 40px;
      height: 40px;
    }
  }
}
