.home-more-partners {
  position: relative;
  padding-top: 3em;
  padding-bottom: 5rem;
  background-color: $color-madison;
}

.home-more-partners, .page-partners {
  .col-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .card {
    padding: 1rem;
    height: 100%;
    background-color: #fff;
    border-width: 0;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    text-align: center;

    top: 0px;
    position: relative;
    z-index: 0;
    overflow: hidden;

    .card-text {
      color: $color-gray;
    }

    .card-img-top {
      width: auto;
      max-width: 50%;
      margin: auto;
    }

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
    }
  
    &:hover:before {
      transform: scale(2.15);
    }
  }
}

.page-partners {
  .card {
    border-width: 1px;
  }
}
