.home-static-cards {
  margin: 3rem 0;

  .card {
    border-width: 0;
    border-radius: 0;
    text-align: center;
    padding: 4rem 8rem;
    height: 100%;
    color: white;

    &.process {
      background-color: $color-madison;
    }

    &.download {
      background-color: $color-tangerine;
    }

    p {
      margin: 2rem 0;
    }

    .btn {
      color: white;
      border-radius: 50rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border-color: white;

      &:hover {
        background-color: white;
        color: $color-jupiter;
      }
    }

    &.download {
      .btn:hover {
        color: $color-tangerine;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .home-static-cards {
    .card {
      padding: 4rem 1rem;
    }
  }
}
