.home-cards {
  .card {
    border-width: 0;
    height: 100%;
    min-height: 4rem;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .card-body {
    padding-bottom: 0;
  }

  .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }

  .list-group-item a {
    color: black;
    text-decoration: none;
  }

  .list-group-item span {
    display: block;
  }

  .list-group-item span.item-title {
    font-weight: bold;
    margin-bottom: .25rem;
  }

  .list-group-item span.item-desc {
    color: $color-gray;
    font-size: 0.8rem;
  }
}
