.banner{
  // background-image: url('../../../static/images/header-single.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  background-position: top center;
  height: 33rem;
  border-top: 1px solid transparent;
  margin-top: -1px;

  &.home {
    // background-image: url('../../../static/images/header.jpg');

    .cta {
      margin: 5rem 5rem;
      max-width: 40rem;
      color: white;
      max-height: calc(100% - 7rem);
      overflow: hidden;
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 2rem;
    }

    .cta-description {
      font-size: 1.5rem;
      font-weight: 300;
      margin-bottom: 2rem;

      p {
        margin: 0
      }
    }

    .cta-btn {
      border-radius: 50rem;
      background-color: $color-tangerine;
      color: #fff;
      border: $color-tangerine;

      &:hover {
        background-color: lighten($color-tangerine, 2);
      }

      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
  }

  &.single {
    height: 29rem;

    .cta {
      margin-top: 8rem;
      color: white;
    }
  }
}

.info-banner {
  h2 {
    max-width: 50rem;
    margin: auto;
  }
}


@include media-breakpoint-down(md) {
  .banner.home {
    .cta {
      margin: 1rem;
    }
  }
}
