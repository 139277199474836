div.alphabetical-listing {
  min-height: 500px;

  .letterGroup {
    > p {
      margin-bottom: 5px;
    }

    > hr {
      margin-top: 0px;
    }

    .item {
      margin-right: 35px;
    }
  }
}
