.footer {
  padding: .5rem 1rem;
  background-color: $color-footer;

  .logo {
    max-height: 60px;
  }

  .nav-link {
    color: #fff;
  }
}
