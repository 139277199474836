.title-bordered {
  &::after {
    content: ' ';
    display: block;
    height: 5px;
    width: 100px;
    background-color: $color-madison;
    margin: 1rem auto;
    border-radius: 50rem;
  }

  &.white {
    color: white;
    &::after {
      background-color: white;
    }
  }
}
.to-left {
  &::after {
    margin: 1rem 0;
  }
}

.title-w100 {
  &::after {
    width: 100%;
  }
}

.title-light {
  &::after {
    background-color: $color-gallery;
  }
}
