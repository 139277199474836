#pg-indicateurs {
  .content-body {
    background-color: white;
    border-radius: 4px;
    padding: 1.75rem;

    > hr {
      margin-top: 0;
    }
  }

  .nav-indicateurs-types {
    .nav-link {
      padding-bottom: 20px;
    }

    .nav-link.active {
      font-weight: bold;
      color: black;
      border-bottom-color: black;
    }
  }
}

.statique-page {
  .featured-image {
    text-align: center;


    img {
      max-width: 100%;
      max-height: 20rem;
    }
  }

  img {
    max-width: 100%;
  }

  .page-body {
    margin-top: -2rem;
    .card {
      min-height: 20rem;
    }
  }
}

.page-more {
  margin-left: -1rem;
}

.rapports-page .page-body {
  margin-top: -2rem;
}
