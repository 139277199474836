.search-box-light {
  padding: 2px 25px 2px 15px;
  border-radius: 50px;
  border: 1px solid #c4d2d3;

  .input-group-prepend {
    padding-top: 7px;
  }

  input {
    border: none;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}
