.map-legend {
    background: #fff;
    list-style: none;
    margin: 10px;
    padding: 0;
    font-size: 0.8rem;
    border-radius: 1px;
    box-shadow: 0 1px 5px rgba(16, 31, 40, 0.2);

    li {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        line-height: 1;
        margin: 0;
        padding: 5px 10px;
    }

    span {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 9px;
    }
}
