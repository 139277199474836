.area-selector {
  margin-bottom: 40px;
  >.content {
    padding: 30px;
    margin-top: 25px;
    background-color: #FFFFFF;
    border-radius: 4px;

    >hr {
      margin-top: 0px;
    }

    .tabs-content {
      .indicateur {
      }
    }

    .area-types-nav {
      .nav-link:first-of-type {
        margin-left: 0;
      }

      .nav-link {
        margin-right: 35px;
      }

      margin-bottom: 25px;
    }
  }


}
