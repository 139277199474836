.search {
  &.input-group {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    border-radius: 50rem;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    }
  }

  .rbt {
    flex: 1 1;

    .form-control {
      padding: .5rem 1rem;
      font-size: 1.1rem;
      line-height: 1.5;
      border-radius: 0;
      height: auto;
      min-height: calc(1.5em + 1.25rem + 2px);
      border-left-width: 0;
    }
  }

  .input-group-text {
    background-color: white;
    border-top-left-radius: 50rem!important;
    border-bottom-left-radius: 50rem!important;
  }

  .btn {
    background-color: $color-tangerine;
    border-color: $color-tangerine;
    color: white;
    border-top-right-radius: 50rem!important;
    border-bottom-right-radius: 50rem!important;

    &:hover {
      background-color: lighten($color-tangerine, 3);
    }
  }

  .rbt-menu {
    border-radius: 0;
    margin-top: -1px;
    border-color: #ced4da;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: .35rem;
    border-bottom-right-radius: .35rem;
  }
}
