.nav.custom-navs {
  > .nav-item {
    > a.nav-link {
      color: black;
      padding: 8px 2px 0px 2px;
      margin: 0px 20px 0px 0px;
    }
    > a.nav-link.active {
      color: #59ADFF;
      border-bottom: #59ADFF solid 2px;
    }
  }
}
