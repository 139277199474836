.navbar-brand .logo {
  max-height: 60px;
}

.navbar {
  background-color: $primary;
}

.navbar-nav {
  .nav-link:not(:first-child):not(:last-child), .nav-item:not(:first-child):not(:last-child) {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .nav-link {
    color: #fff !important;
    border-bottom: 4px solid transparent;
    font-weight: normal;

    &.active {
      border-bottom-color: #fff;
    }
  }

  .dropdown {
    .dropdown-menu {
      border-radius: 0;
      border-width: 0;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    }

    .dropdown-item {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }

    .dropdown-toggle::after {
      vertical-align: middle;
    }
  }
}
